.item-group {
  --xyz-translate-x: 1000%;
  overflow: hidden;
  display:flex;
  flex-direction: row;
  margin:auto;
  text-align:center ;
  margin-left:0px;
  justify-content: center;
  
}

.invis{
  width:22%;
  margin-right:auto;
  margin-top: 20px;
  height:100%;
}